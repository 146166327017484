//Core
import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import get from 'lodash.get';
import ColumnPostItem from 'src/components/Column/News/items/ColumnPostItem';
//Local
import { recommendStyles } from '../styles';

const ReccomendSec = ({ recommendedArticles }) => {
  const styles = recommendStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const recommendedArticlesJSX = recommendedArticles.map((item, index) => (
    <ColumnPostItem
      key={index}
      itemUrl={`/news/article/${item?.sys?.id}`}
      title={get(item, 'fields.title')}
      tags={get(item, 'fields.tags', []).map((itemm) => itemm.fields.title)}
      previewImage={get(item, 'fields.previewImage.fields.file.url')}
      index={index}
    />
  ));

  return (
    <Container className={styles.reccomendSec}>
      <Typography className={styles.title} variant="h3">
        こちらもオススメ
      </Typography>
      <Grid container spacing={isSmall ? 0 : 3}>
        {recommendedArticlesJSX}
      </Grid>
    </Container>
  );
};

export default ReccomendSec;
