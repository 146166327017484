/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import cross from '../../../images/cross.svg';
import blogAuthor from '../../../images/blogAuthor.svg';

export const articleStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 37,
  },
  subtitle: {
    marginBottom: 40,
    color: '#777A7F',
  },
  info: {
    borderBottom: '1px solid #CFC4C5',
  },
  date: {
    marginBottom: 10,
  },
  tags: {
    marginTop: 40,
  },
  tag: {
    background: '#EBE6E2',
    borderRadius: 16,
    padding: '7px 30px',
    '&:not(:first-child)': {
      marginLeft: 22,
    },
  },
  footShare: {
    marginTop: 40,
  },
  content: {
    marginTop: 40,
    '& h1, h2, h3, h4, h5, h6, p, ol, ul, img, hr, blockquote': {
      marginBottom: 20,
    },
    '& ol': {
      listStyle: 'decimal',
      paddingLeft: 24,
    },
    '& b': {
      fontWeight: 'bold',
    },
    '& i': {
      fontStyle: 'italic',
    },
    '& blockquote, & blockquote p': {
      font: `400 14px/24px ${theme.typography.fontFamily} !important`,
      color: '#777A7F',
    },
  },
  contentImgWrap: {
    '& img': {
      maxWidth: '100%',
      borderRadius: 20,
    },
    borderRadius: 20,
    fontSize: 0,
  },
  popup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popupContent: {
    position: 'relative',
    maxWidth: 1300,
    '&>img': {
      maxWidth: '100%',
      borderRadius: 35,
    },
  },
  popupClose: {
    width: 18,
    height: 18,
    backgroundImage: `url(${cross})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    right: 22,
    top: -38,
  },
}));

export const authorStyles = makeStyles(() => ({
  authorSec: {
    backgroundColor: '#EBE6E2',
    padding: '45px 80px 80px 80px',
    width: 'calc(100% + 160px)',
    marginLeft: -80,
  },
  sectionTitle: {
    marginBottom: 24,
    marginTop: 65,
  },
  img: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    borderRadius: '100%',
    display: 'inline-block',
    backgroundImage: `url(${blogAuthor})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    marginBottom: 11,
    fontWeight: '700!important',
  },
  subtitle: {
    marginBottom: 24,
  },
}));

export const breadcrumbsStyles = makeStyles(() => ({
  breadcrumbs: {
    marginBottom: 35,
  },
  link: {
    color: '#777A7F',
    textDecoration: 'underline',
  },
}));

export const recommendStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    paddingBottom: 24,
    marginBottom: 56,
    marginTop: 60,
  },
}));
