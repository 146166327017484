//@flow
//Core
import React, { useState, useEffect } from 'react';
import get from 'lodash.get';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Box } from '@material-ui/core';
import formatDate from 'date-format';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import * as MediaCenterActions from 'src/redux/mediaCenter/actions';
import imgNewsBanner from 'src/images/news-banner.png';
//Local
import { Layout } from '../components';
import {
  BreadcrumbsSec,
  ArticleSec,
  AuthorSec,
  RecommendSec,
} from '../components/News/Article';
import { articleStyles } from '../styles/pages/media_center.styles';
import { getOgData } from '../utils';

export const query = graphql`
  query($tagsId: [String], $contentfulId: String) {
    allContentfulComponentArticle(
      limit: 1000
      filter: {
        tags: { elemMatch: { id: { in: $tagsId } } }
        contentful_id: { ne: $contentfulId }
      }
    ) {
      edges {
        node {
          title
          contentful_id
          date
          blogSection {
            urlKey
          }
          previewImage {
            file {
              url
            }
          }
          tags {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        ogImage
        twitterImage
        siteUrl
      }
    }
  }
`;

const Article = ({ pageContext, data, location }) => {
  const styles = articleStyles();
  const [imgPopupState, setImgPopupState] = useState({
    isOpen: false,
    url: null,
  });

  const articleId = get(pageContext, 'contentfulId');
  const sessionUrl = get(pageContext, 'blogSection.urlKey');
  const breadcrumbs = [
    {
      title: 'トップ',
      url: '/',
    },
    {
      title: 'ニュースルーム',
      url: '/news/',
    },
    {
      title: get(pageContext, 'title'),
    },
  ];

  const handleImgClick = (event) => {
    setImgPopupState({
      isOpen: true,
      url: event.target.src,
    });
  };

  const handleClosePopup = () => {
    setImgPopupState({
      isOpen: false,
      url: null,
    });
  };

  const dispatch = useDispatch();

  const { data: pressArticles } = useSelector(
    (state) => state.mediaCenter.pressArticles
  );

  useEffect(() => {
    dispatch(MediaCenterActions.getPressArticlesAsync());
  }, [dispatch]);

  return (
    <Layout
      SEOProps={{
        title: get(pageContext, 'title'),
      }}
    >
      <Helmet
        meta={getOgData({
          img: get(pageContext, 'previewImage'),
          title: get(pageContext, 'title'),
          siteMetadata: get(data, 'site.siteMetadata'),
        })}
      >
        {sessionUrl === 'fun' && (
          <meta
            httpEquiv="refresh"
            content={`0; url=/column/article/${articleId}`}
          />
        )}
      </Helmet>
      <Box className={styles.articlePage}>
        <Box
          className={styles.articlePageBg}
          style={{
            backgroundImage: `url(${imgNewsBanner})`,
          }}
        />

        <Container className={styles.container}>
          <Box className={styles.paper}>
            <BreadcrumbsSec
              items={breadcrumbs}
              tag={get(pageContext, 'tags[0]', {})}
            />
            <ArticleSec
              title={get(pageContext, 'title')}
              subtitle={get(pageContext, 'subtitle')}
              date={formatDate(
                'yyyy/MM/dd',
                new Date(
                  get(pageContext, 'date') || get(pageContext, 'createdAt')
                )
              )}
              author={get(pageContext, 'author')}
              tags={get(pageContext, 'tags', [])}
              content={get(pageContext, 'content.json')}
              imgPopupState={imgPopupState}
              handleImgClick={handleImgClick}
              handleClosePopup={handleClosePopup}
              shareUrl={location.href}
              sessionUrl={sessionUrl}
            />
            {get(pageContext, 'authorBlock') && (
              <AuthorSec
                image={get(pageContext, 'authorBlock.image.file.url')}
                name={get(pageContext, 'authorBlock.name')}
                surname={get(pageContext, 'authorBlock.surname')}
                jobTitle={get(pageContext, 'authorBlock.jobTitle')}
                description={get(pageContext, 'authorBlock.description')}
              />
            )}
          </Box>
          {!!pressArticles.length && (
            <RecommendSec recommendedArticles={pressArticles.slice(0, 3)} />
          )}
        </Container>
      </Box>
    </Layout>
  );
};

export default Article;
